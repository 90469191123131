








import Vue from 'vue';
import Worklist from '../components/Worklist.vue';


export default Vue.extend({
  name: 'WorklistHome',

  components: { 
    Worklist,
  },

  data: () => ({   
      userId: null
  }),
  mounted() {
    this.userId = this.$store.state.achillesUser.userId;
  },
});
